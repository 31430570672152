export interface ICard {
  IsVariablePriceItem: boolean
  MaximumVariablePriceInCents: number
  MinimumVariablePriceInCents: number
  description: string
  id: string
  image: string
  price: string
  shortImage: string
  tax: number
  name?: string
}
export enum GIFT_CARD_TYPE {
  E_GIFT_CARD = "e-gift",
  PHYSICAL_CARD = "physical",
  EXPERIENCES_CARD = "experience",
}

export const GIFT_CARD_ACTION = {
  LIST: 'list',
  CARD: 'card',
  CART: 'cart'
}
export const GiftType = {
  ecard: 1,
  physical: 2,
  exp: 3
}
export const stateNZCard = [
  { value: 'North Island', label: 'North Island' },
  { value: 'South Island', label: 'South Island' },

];
export const stateAUSCard = [
  { value: 'New South Wales', label: 'New South Wales' },
  { value: 'Victoria', label: 'Victoria' },
  { value: 'Western Australia', label: 'Western Australia' },
  { value: 'Queensland', label: 'Queensland' },
  { value: 'Tasmania', label: 'Tasmania' },
  { value: 'South Australia', label: 'South Australia' },
];

export const stateANGCard = [
  { value: 'New South Wales', label: 'New South Wales' },
  { value: 'Victoria', label: 'Victoria' },
  { value: 'Western Australia', label: 'Western Australia' },
  { value: 'Queensland' , label: 'Queensland' },
  { value: 'Tasmania', label: 'Tasmania' },
  { value: 'South Australia', label: 'South Australia' },
];

export const stateANGSCard = [
  { value: 'New South Wales' , label: 'New South Wales' },
  { value: 'Victoria', label: 'Victoria' },
  { value:  'Western Australia', label: 'Western Australia' },
  { value:  'Queensland', label: 'Queensland' },
  { value:  'Tasmania' , label: 'Tasmania' },
  { value:  'South Australia', label: 'South Australia' },
];
export const stateUSCard = [
  { value: 'California' , label: 'California' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Illinois', label: 'Illinois' },
  { value:  'Texas', label: 'Texas' },
  { value:  'Virginia' , label: 'Virginia' },
  { value:  'Washington', label: 'Washington' },
];
export const DeliveryFee = {
  nz: 9.57,
  aus: 9,
  ang: 9,
  sta: 9
}