export const ROUTES = {
    //AU
    MOVIES: 'movies',
    NOW_SHOWING: 'now-showing',
    COMING_SOON: 'coming-soon',
    SPECIAL_EVENTS: 'special-events',
    KIDS_NOW_SHOWING: 'kids-corner/now-showing',
    KIDS_COMING_SOON: 'kids-corner/coming-soon',
    KIDS_ACTIVITIES: 'kids-corner/activities',
    MOVIE_DETAILS: ':cinemaId/movies/details/:movieGroupId',
    MOVIE_DETAILS_AU: 'movies/details/:movieGroupId',
    MOVIE_DETAIL_SEARCH: 'movies/details/:cinemaId/:movieGroupId',
    MOVIE_DETAILS_COMING_SOON: ':cinemaId/movies/details',
    PRINT_SESSIONS: 'printsession',
    CINEMAS: 'cinemas',
    GIFTSHOP: 'giftcards',
    ECARDS: 'e-gift-cards',
    PHYSICAL_CARDS: 'physical-gift-cards',
    EXPERIENCE: 'experience',
    MEMBERSHIPS: 'memberships',
    FAQ: 'faq',
    OFFERS_EVENTS: 'offersAndEvents',
    TICKETS: 'sessions/:sessionId/:movieId',
    TICKET_BOOKING: '/cinemas/:cinemaId/sessions/:sessionId/:movieId',
    FOOD_AND_BEVERAGE: 'cinemas/:cinemaId/foodandbevarages',
    HISTORY:'history',
    ABOUT:'about',
    ADVERTISING:'advertising',
    REELCLUB:'reelclub',
    CINEMONY: 'cinemony',
    CINEMONYMODAL: '/cinemony',  
    COMPETITIONS:'competitions',
    MAILINGLIST:'mailinglist',
    MAILINGLISTMODAL: '/mailinglist',
    CAFEBAR: 'cafe-bar',
    HIGHLINE_TERRACE:'highline-terrace',
    MEMBERSHIP: 'membership',
    MEMBERSHIP_STATE: 'memberships',
    LOYALTY_MEMBERSHIP: 'loyalty-membership',
    SOHOLOUNGE: 'soho-lounge',
    GAMING_HIRE: 'gaming-hire/bookings',
    SEARCH_RESULT: 'search-result',
    SITE_DISCLAIMER: 'site-disclaimer',
    REELCLUB_TERMS: 'reel-club-terms',
    REWARDS:'rewards',
    SUMMARY:'summary',
    REWARDITEM: 'reward-item',
    BENEFITS: 'benefits',
    PROFILE: 'profile',
    RENEWAL: ':renewal',
    TRANSACTION: 'transaction',
    LUXURY_CINEMAS: 'luxury-cinemas/cinema',
    LUXURY_CINEMAS_CATEGORY: 'luxury-cinemas/:category',
    LUXURY_CINEMAS_SESSION:'cinemas/:category/:cinema',
    REFUND: ':cinemaId/refund',
    SWAP_SEAT: ':cinemaId/seat-swap',
    SOCIAL_SHARE: 'sessions/:sessionId/:movieId/:sharedUserSessionId',
    CONTACT_US: 'contact-us',
    PRIVACY_POLICY:'privacy-policy',
    TERMS: 'terms',
    RATING: 'rating',
    NEWS_LETTER: 'newsletter',
    CAREER: 'employment',
    GROUP_SALE: 'group-sale',
    CINEMA_HIRE: 'cinema-hire',
    VIP_EXPERIENCE: 'vip-cinemas/bookings',
    GIFT_CART: 'my-cart',
    RESET_PASSWORD: "account/resetPassword",
    CONFIRM_USER:"account/confirmUser",
    SEARCH:'search',
    PAGE_NOT_FOUND: 'page-not-found',
    FOOD_IAM_HERE:"am-here/:code",
    CANCEL_MEMBERSHIP: "cancel-subscription",
    EDIT_RENEWAL: "edit-renewal",
    MEMBERSHIP_ACTIVATE_INFO: "membership-activate-info",
    MEMBERSHIP_ACCOUNT_VERIFY_INFO: "membership-account-verify-info",
    GROUPS: 'groups'
}
