import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GiftCardForm from "../../../../components/GiftCard/GiftCardForm";
import {
  API_REQUEST_TYPE,
  IMAGE_URL_CONFIG,
  URLS,
} from "../../../../constants/url";
import { GIFT_CARD_ACTION, GIFT_CARD_TYPE } from "../../../../models/giftcard";
import { RootState } from "../../../../store";
import {
  getGiftCardWatcher,
  setGiftShopAction,
} from "../../../../store/giftshop/action";
import "./cards.scss";
import { COUNTRY, GIFT_CARD_CINEMA_ID } from "../../../../constants/app";

interface IEcardsProps {}

const Cards: FC<IEcardsProps> = () => {
  const { countryId, currentTab, cards, currentAction, giftFormVisible } =
    useSelector((state: RootState) => ({
      countryId: state.applicationReducer.countryId as COUNTRY,
      currentTab: state.giftCardReducer.currentTab,
      currentAction: state.giftCardReducer.currentAction,
      cards: state.giftCardReducer.cards,
      giftFormVisible: state.giftCardReducer.giftFormVisible,
    }));
  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState([]);
  const [giftType, setGiftType] = useState<string>("");
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [selectedCard, setSelectedCard] = useState<any>();

  const getGiftCards = async () => {
    const payload = {
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      countryId: countryId,
      requestType: API_REQUEST_TYPE.GIFT_CARDS,
      giftType: currentTab?currentTab:GIFT_CARD_TYPE.E_GIFT_CARD,
    };
    dispatch(getGiftCardWatcher(payload));
  };


  useEffect(() => {
      setCardDetails(cards);
  }, [cards]);

  useEffect(() => {
    if (countryId) {
      getGiftCards();
    }
  }, [countryId, currentTab]);

  useEffect(() => {
    if (!giftFormVisible) {
      setShowGrid(true);
    }
  }, [giftFormVisible]);

  let onClickBuy = (item: any) => {
    dispatch(setGiftShopAction(GIFT_CARD_ACTION.CARD));
    setSelectedCard(item);
    setShowForm(true);
    setShowGrid(false);
  };

  return (
    <>
      {currentAction === GIFT_CARD_ACTION.CARD ? (
        <GiftCardForm card={selectedCard} />
      ) : (
        <div className="row cards-gift-card">
          <div className="col-md-12">
            <div className="giftcard_list_wrap">
              <ul className="e_giftcard_list">
                {cardDetails &&
                  cardDetails.length > 0 &&
                  cardDetails.map((item: any, index: number) => (
                    <li
                      key={index}
                      onClick={() => {
                        onClickBuy(item);
                      }}
                    >
                      <div className="e_giftcard_item_thumb">
                        <img
                          src={
                            currentTab !== GIFT_CARD_TYPE.E_GIFT_CARD
                              ? `${URLS.CLOUDFRONT_URL}/${IMAGE_URL_CONFIG.IMAGE_PATH}/${item.image}`
                              : item.image
                          }
                          alt=""
                          onError={(e: any) =>
                            (e.target.src = URLS.GIFT_CARD_IMAGE)
                          }
                        />
                      </div>
                      <div className="e_giftcard_item_details_wrap">
                        <p className="e_giftcard_item_name">
                          {item.name ? item.name : item.description}
                        </p>
                        <div className="e_giftcard_item_price_sec">
                          <p className="e_giftcard_item_price">
                            $
                            {item.IsVariablePriceItem
                              ? item.MinimumVariablePriceInCents
                              : item.price}
                          </p>
                          <a href="javascript:void(0)" className="white_btn">
                            Buy now
                          </a>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              {cardDetails && cardDetails.length === 0 ? (
                <div className="no-data">
                  <h4>No cards available</h4>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cards;
