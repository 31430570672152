import { COUNTRY, GIFT_CARD_DELIVERY_FEE } from "../constants/app";
import { GiftType } from "../models/giftcard";
import { ICartData } from "../store/giftshop/type";

export const calTotal = (cartData: ICartData[], countryId: COUNTRY) => {
  let fee: number = 0;
  if (countryId === COUNTRY.NZ) {
    fee = GIFT_CARD_DELIVERY_FEE.NZ;
  } else if (countryId === COUNTRY.ANG) {
    fee = GIFT_CARD_DELIVERY_FEE.ANG;
  } else if (countryId === COUNTRY.STA) {
    fee = GIFT_CARD_DELIVERY_FEE.STA;
  } else if (countryId === COUNTRY.AUS) {
    fee = GIFT_CARD_DELIVERY_FEE.AU;
  } else if (countryId === COUNTRY.US) {
    fee = GIFT_CARD_DELIVERY_FEE.AU;
  }
  let total = cartData.reduce((sum: number, item: ICartData) => sum + item.itemTotal, 0);
  let phyCards = cartData
    .filter((item: ICartData) => item.giftcardType != GiftType.ecard);
  let phyTotal = phyCards.filter(
    (v, i, a) => a.findIndex((t: ICartData) => ((t.address1).toLowerCase() === (v.address1).toLowerCase())) === i
  );
  let totalFee = phyTotal.length * fee!;
  let deliveryFee = +totalFee;
  let deliveryQty = phyTotal.length;
  let finalAmount = (total + totalFee);

  return { deliveryFee, deliveryQty, total: finalAmount, deliveryCharge: fee }

}