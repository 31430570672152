import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { GiftType } from "../../../../models/giftcard";
import { URLS } from "../../../../constants/url";
import "./cart.scss";
import moment from "moment";
import {
  addGiftCart,
  giftCardOrderSuccess,
  setGiftModalVisible,
} from "../../../../store/giftshop/action";
import { ICartData } from "../../../../store/giftshop/type";
import Modal from "../../../../components/UI/Modal";
import GiftCardForm from "../../../../components/GiftCard/GiftCardForm";
import { calTotal } from "../../../../utils/giftshop";
import {
  BRANDS,
  COUNTRY,
  GIFT_CARD_CINEMA_ID,
  GIFT_CARD_DELIVERY_FEE,
} from "../../../../constants/app";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../constants/path";
import RemoveGiftCard from "../../../../components/GiftCard/RemoveCard";
import { generateUsersessionId } from "../../../../utils/tickets";
import { giftCardOrderApi } from "../../../../services/payment";
import { toast } from "react-toastify";
import {
  setModal,
  toggleTicketModal,
} from "../../../../store/ticketBooking/action";
import { TICKET_FLOW } from "../../../../constants/ticket";
import { MODAL_TYPE, TICKET_FLOW_TYPES } from "../../../../models/tickets";
import GiftShopFooter from "../Footer";
import HelmetTitle from "../../../../components/Helmet";


interface ICardProps {}

const Cart: React.FC<ICardProps> = () => {
  const {
    brandId,
    currentTab,
    countryId,
    cart,
    giftModalVisible,
    currentCinema,
  } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    currentTab: state.giftCardReducer.currentTab,
    currentCinema: state.applicationReducer.currentCinema,
    countryId: state.applicationReducer.countryId as COUNTRY,
    cart: state.giftCardReducer.cart,
    giftModalVisible: state.giftCardReducer.giftModalVisible,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editCardData, setEditCardData] = useState<any>();
  const [index, setIndex] = useState<number>(0);
  const [action, setAction] = useState("");
  const [cartData, setCartData] = useState<{
    deliveryFee: number;
    deliveryQty: number;
    total: number;
  }>({ deliveryFee: 0, deliveryQty: 0, total: 0 });

  useEffect(() => {
    if (countryId && cart) {
      setCartData(calTotal(cart, countryId));
    }
  }, [cart, countryId]);

  const removeCard = async (index: number) => {
    let cards = cart;
    cards = cards.filter((item: any, ind: number) => ind != index);
    await dispatch(addGiftCart(cards));
  };
  const updateAmount = (cardData: ICartData, index: number) => {
    if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
      cardData.itemTotal =
        Number(cardData.quantity) * Number(cardData.giftamount);
    } else {
      cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
    }
    const cartData = [...cart];
    cartData[index] = cardData;
    dispatch(addGiftCart(cartData));
    dispatch(setGiftModalVisible(false));
    setAction("");
  };

  useEffect(() => {
    if (cart && cart.length === 0) {
      if (brandId === BRANDS.US) {
        navigate(`${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
      } else {
        navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
      }
    }
  }, [cart]);

  const onClickminus = (card: any, index: number) => {
    const qty = card.quantity;
    if (qty > 1) {
      const cardData = { ...card };
      cardData.quantity = qty - 1;
      if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
        cardData.itemTotal =
          Number(cardData.quantity) * Number(cardData.giftamount);
      } else {
        cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
      }
      //setCard(cardData);
      const cartData = [...cart];
      cartData[index] = cardData;
      dispatch(addGiftCart(cartData));
    }
  };
  const openModal = (cartData: any, index: number) => {
    setEditCardData(cartData);
    setIndex(index);
    dispatch(setGiftModalVisible(true));
  };

  const onClickplusCart = async (card: any, index: number) => {
    const qty = card.quantity;
    if (qty < 10) {
      const cardData = { ...card };
      cardData.quantity = qty + 1;
      updateAmount(cardData, index);
      if (Number(cardData.giftamount) > 0 && cardData.isVariablePriceItem) {
        cardData.itemTotal =
          Number(cardData.quantity) * Number(cardData.giftamount);
      } else {
        cardData.itemTotal = Number(cardData.quantity) * Number(cardData.price);
      }
      //setCard(cardData);
      const cartData = [...cart];
      cartData[index] = cardData;
      dispatch(addGiftCart(cartData));
    }
  };

  const backToGiftShop = () => {
    if(brandId === BRANDS.US){
      navigate(`/${currentCinema?.alias}/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
    }else{
      navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`);
    }
  
  };

  const openRemoveConfirmation = () => {
    setAction("remove");
  };

  const cancelRemove = () => {
    setAction("edit");
  };

  const openPaymentModal = async () => {
    generateUsersessionId("giftshop");
    const orders = {
      giftcards: cart.map((v: ICartData) => {
        return {
          quantity: v.quantity,
          giftamount: !v.isVariablePriceItem ? v.price : v.giftamount,
          recipientname: v.recipientName,
          recipientemail: v.recipientemail,
          confirmemail: v.confirmRecipientEmail,
          yourname: v.senderName,
          phone: v.phone,
          giftMessage: v.giftMessage,
          id: v.id,
          image: v.image,
          description: v.description,
          price: v.price,
          tax: v.tax,
          deliverydate: v.deliveryDate,
          giftcard_type: v.giftcardType,
          itemTotal: v.itemTotal,
          IsVariablePriceItem: v.isVariablePriceItem,
          MinimumVariablePriceInCents: v.minimumVariablePriceInCents,
          MaximumVariablePriceInCents: v.maximumVariablePriceInCents,
          name: v.carddesign,
          carddesign: v.carddesign,
          eGiftcardDesign: v.image,
          type: v.type,
          delivery_add_1: v.address1,
          delivery_add_2: v.address2,
          city: v.city,
          state: v.state,
          postcode: v.postCode,
        };
      }),
      subTotal: cartData.total.toFixed(2),
      totalTaxPrice: "0.00",
      totalPrice: cartData.total.toFixed(2),
      deliveryFee: cartData.deliveryFee.toFixed(2),
      totalQuantity: cart.length,
    };

    const payload = {
      userSessionId: generateUsersessionId("giftshop"),
      method: "giftcards",
      cinemaId: GIFT_CARD_CINEMA_ID[countryId],
      step: 1,
      requestMethod: "GiftcardshopOrder",
      countryId: countryId,
      order: orders,
    };
    const { status, data, error }: any = await giftCardOrderApi(payload);
    if (status) {
      dispatch(giftCardOrderSuccess(data));
      const modal =
        TICKET_FLOW[TICKET_FLOW_TYPES.GIFTSHOP][MODAL_TYPE.GIFTSHOP_PAYMENT];
      dispatch(
        setModal({
          ...modal,
          type: TICKET_FLOW_TYPES.GIFTSHOP,
        })
      );
      dispatch(toggleTicketModal(true));
    } else {
      toast.error(error ? error : "Error occured while making payment");
    }
  };

  return (
    <div className="gift-cart-wrapper">
      <HelmetTitle title="My Gift Cart" description="My Gift Cart"/>
      <div className="gift-addcart-main">
        <h3>
          <img src={URLS.GIFT_CART} alt="" />
          My Gift Cart
          {/* <span>
            {carts.length} <label>ITEMS</label>
          </span> */}
        </h3>
        <div className="gift-addcart-inner">
          <div className="gift-addcart-inner-cont">
            <ul>
              {cart &&
                cart.map((item: any, index: any) => (
                  <li id="cart" key={index}>
                    <div className="cart-detail-left">
                      {item.giftcardType == GiftType.ecard ? (
                        <div className="cartImg">
                          <img
                            src={item.image}
                            alt=""
                            onError={(e: any) =>
                              (e.target.src = URLS.GIFT_CARD_IMAGE)
                            }
                          />
                        </div>
                      ) : (
                        <div className="cartImg">
                          <img src={item.image} alt="" />
                        </div>
                      )}
                      <div className="cartContent">
                        <h2>
                          <strong>
                            {item.name ? item.name : item.description}
                          </strong>
                        </h2>
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Gift Type: <span>E-Gift Card</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.physical ? (
                          <p>
                            Gift Type: <span>Physical Gift Card</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.exp ? (
                          <p>
                            Gift Type: <span>Experience</span>
                          </p>
                        ) : null}
                        <p>
                          Gift To: <span>{item.recipientName}</span>
                        </p>
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Deliver To: <span>{item.recipientemail}</span>
                          </p>
                        ) : null}
                        {item.giftcardType == GiftType.ecard ? (
                          <p>
                            Delivery Date:{" "}
                            <span>{`${moment(item.deliveryDate).format(
                              "DD MMM YYYY"
                            )}`}</span>
                          </p>
                        ) : null}
                        {item.giftcardType !== GiftType.ecard ? (
                          <p>
                            Deliver To:
                            <span>
                              {item.delivery_add_1}
                              {item.delivery_add_2}
                              {item.city},{item.state},{item.postcode}
                            </span>
                          </p>
                        ) : null}
                        <p>
                          From: <span>{item.senderName}</span>
                        </p>
                        <p>
                          Message: <span>{item.giftMessage}</span>
                        </p>
                        <p className="mob-field">
                          Item Total: <span>${item.itemTotal}</span>
                        </p>
                      </div>
                      <div className="cartEdit cartDeladd">
                        <div className="mob-div">
                          <img
                            src={URLS.GIFT_CARD_DEL}
                            alt="del"
                            aria-label="Close"
                            className="imgDel"
                            onClick={() => {
                              removeCard(index);
                            }}
                          />
                        </div>
                        <div>
                          <img
                            src={URLS.GIFT_CARD_EDIT}
                            alt=""
                            aria-label="Close"
                            onClick={() => {
                              setAction("edit");
                              openModal(item, index);
                            }}
                          />
                        </div>
                        <div className="nor-div">
                          <img
                            src={URLS.GIFT_CARD_DEL}
                            alt="del"
                            aria-label="Close"
                            className="imgDel"
                            onClick={() => {
                              removeCard(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cart-detail-right cart-count-right">
                      <div className="cart-detail-count">
                        <div className="form-group quantity_wrap">
                          <div className="mob-qty">Quantity</div>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <button
                                className="btn_minus"
                                onClick={() => {
                                  onClickminus(item, index);
                                }}
                              >
                                &nbsp;
                              </button>
                            </div>
                            <input
                              type="number"
                              className="form-control"
                              aria-label="qantity"
                              disabled
                              value={item.quantity}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn_plus"
                                onClick={() => {
                                  onClickplusCart(item, index);
                                }}
                              >
                                &nbsp;
                              </button>
                            </div>
                            {/* <div className="error_message">
        <span>Minimum one quantity is required</span>
    </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="cart-detail-price">
                        <p>${item.itemTotal}</p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            {cartData.deliveryFee ? (
              <div className="cart-detail-delivery">
                <h2>${cartData.deliveryFee.toFixed(2)} Delivery Fee</h2>
                <ul>
                  {cart &&
                    cart.map((item: any, index: any) => {
                      return (
                        <>
                          {item.giftcardType != GiftType.ecard ? (
                            <li id="cart" key={index}>
                              <p>
                                Deliver To:
                                <span>
                                  {" "}
                                  {item.address1}
                                  {item.address2}
                                  {item.city}, {item.state}, {item.postCode}
                                </span>
                              </p>
                            </li>
                          ) : null}
                        </>
                      );
                    })}
                </ul>
              </div>
            ) : null}

            <div className="cart-total">
              <div className="cart-total-left">
                <label>TOTAL ORDER</label>
                <h2>${cartData.total.toFixed(2)}</h2>
              </div>
              <div className="cart-total-right">
                <div className="btn-section">
                  <button
                    type="button"
                    className="btn green_btn"
                    onClick={openPaymentModal}
                  >
                    Checkout ({cart.length})
                  </button>
                  <button
                    type="button"
                    className="btn black_btn"
                    onClick={backToGiftShop}
                  >
                    Continue Shopping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {}
        {brandId !== BRANDS.US ? <GiftShopFooter page="giftshop" /> : null}
      </div>
      <Modal visible={giftModalVisible} className="edit-cart-popup">
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-lg ">
            <div className="modal-content">
              {action === "remove" ? (
                <div className="modal-header">
                  <h3 className="modal-title" style={{ fontSize: 22 }}>
                    Remove gift?
                  </h3>
                </div>
              ) : null}
              <button
                className="close btn-close "
                type="button"
                onClick={() => dispatch(setGiftModalVisible(false))}
              >
                <span className="close_icon"></span>
              </button>
              <div className="modal-header ng-star-inserted"></div>
              <div className={`modal-body ${action === "remove" ? "" : "p-0"}`}>
                {action === "remove" ? (
                  <RemoveGiftCard
                    cancelRemove={cancelRemove}
                    item={editCardData}
                    index={index}
                    removeCard={removeCard}
                  />
                ) : (
                  <GiftCardForm
                    isEdit={true}
                    item={editCardData}
                    index={index}
                    removeGiftCard={openRemoveConfirmation}
                  ></GiftCardForm>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Cart;
