import { takeLatest, call, put } from 'redux-saga/effects';
import { ACTIONS } from './type';
import { IAction } from '../../models/common';
import { getCardCategoriesApi, getCardDesignApi, getGiftCardApi } from '../../services/giftshop';
import { getCardCategoryError, getCardCategorySuccess, getCardDesignError, getCardDesignSuccess, getGiftCardError, getGiftCardSuccess } from './action';

function* getCardDetails(action: IAction) {
    let { payload } = action
    const { data, error } = yield call(getGiftCardApi, payload)
    if (data && (data.giftcards || data.Items) ) {
        const { giftcards, Items, statusCode } = data;

        let cardDetails = [];
        if (statusCode == 200 && giftcards.length > 0) {
            cardDetails = giftcards;
        }
        else if (Items && Items.length > 0) {
            let phyRes = Items.sort((a: any, b: any) => {
                return a.sortorder - b.sortorder;
            });
            cardDetails = phyRes;
        }
        yield put(getGiftCardSuccess(cardDetails));
    } else {
        yield put(getGiftCardError(error));
    }
}
function* getCardDesigns(action: IAction) {
    const { payload } = action;
    const { data, error } = yield call(getCardDesignApi, payload);
    if (data) {
        const { Items } = data;
        yield put(getCardDesignSuccess(Items))
    } else {
        yield put(getCardDesignError(error))
    }
}


function* getCardCategory(action: IAction) {
    let { payload } = action
    const { data, error } = yield call(getCardCategoriesApi, payload)
    if (data) {
        const { Items } = data;
        yield put(getCardCategorySuccess(Items));
    } else {
        yield put(getCardCategoryError(error));
    }
}

export function* giftshopWatcher() {
    yield takeLatest(ACTIONS.GET_CARD_WATCHER, getCardDetails);
    yield takeLatest(ACTIONS.GET_CARD_DESIGN_WATCHER, getCardDesigns);
    yield takeLatest(ACTIONS.GET_CARD_CATEGORY_WATCHER, getCardCategory)
}