import { ACTIONS } from './type';

export function getGiftCardWatcher(payload: any) {
  return { type: ACTIONS.GET_CARD_WATCHER, payload: payload }
}

export function getGiftCardSuccess(payload: any) {
  return { type: ACTIONS.GET_CARD_SUCCESS, payload: payload }
}

export function getGiftCardError(payload: any) {
  return { type: ACTIONS.GET_CARD_ERROR, payload: payload }
}

export function setGiftShopTab(payload: string) {
  return { type: ACTIONS.SET_GIFT_SHOP_TAB, payload }
}

export function setGiftShopAction(payload: string) {
  return { type: ACTIONS.SET_GIFT_SHOP_ACTION, payload }
}

export function getCardDesignWatcher(payload: any) {
  return { type: ACTIONS.GET_CARD_DESIGN_WATCHER, payload: payload };
}

export function getCardDesignSuccess(payload: any) {
  return { type: ACTIONS.GET_CARD_DESIGN_SUCCESS, payload: payload };
}

export function getCardDesignError(payload: any) {
  return { type: ACTIONS.GET_CARD_DESIGN_ERROR, payload: payload };
}

export function getCardCategory(payload: any){
  return { type: ACTIONS.GET_CARD_CATEGORY_WATCHER, payload: payload };
}

export function getCardCategorySuccess(payload: any) {
  return { type: ACTIONS.GET_CARD_CATEGORY_SUCCESS, payload: payload };
}

export function getCardCategoryError(payload: any) {
  return { type: ACTIONS.GET_CARD_CATEGORY_ERROR, payload: payload };
}

export function addGiftCart(payload: any) {
  return { type: ACTIONS.ADD_GIFT_CART, payload: payload };
}

export function setGiftFormVisible(payload: boolean) {
  return { type: ACTIONS.SET_GIFT_FORM_VISIBLE, payload }
}
export function setGiftModalVisible(payload: boolean) {
  return { type: ACTIONS.SET_GIFT_MODAL_VISIBLE, payload }
}

export function giftCardOrderSuccess(payload: any) {
  return { type: ACTIONS.GIFT_CARD_ORDER_SUCCESS, payload: payload };
}

export const updateGiftCardBookingIdAction = (payload: string) => {
  return { type: ACTIONS.UPDATE_GIFT_CARD_BOOKING_ID, payload };
};

export const clearOrdersAction = () => {
  return { type: ACTIONS.CLEAR_GIFT_CARD_ORDERS };
};

export const setTransactionErrorAction = (payload: string) => {
  return { type: ACTIONS.SET_TRANSACTION_ERROR, payload }
}
